
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import {
  onMounted,
  ref,
  toRefs,
  computed,
  defineComponent,
  reactive,
} from "vue";
import { useRouter } from "vue-router";
import { DisponibilidadRequest } from "@/models/disponibilidad-request.model";
import { Sucursal } from "@/models/sucursal.model";
import { Servicio } from "@/models/servicio.model";

export default defineComponent({
  name: "inicio",

  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { id } = toRefs(props);

    let servicios = ref({});

    const store = useStore();

    const router = useRouter();

    let sucursalSeleccionada: Sucursal;

    const consultandoEstablecimiento = computed(
      () => store.getters["establecimiento/getterConsultandoEstablecimiento"]
    );

    const sucursales = computed(
      () => store.getters["sucursales/getterSucursales"]
    );

    const establecimiento = computed(
      () => store.getters["establecimiento/getterEstablecimiento"]
    );

    const saveChanges = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
        let disponibilidadRequest: DisponibilidadRequest =
          new DisponibilidadRequest();
        disponibilidadRequest.sucursal = sucursalSeleccionada;
        disponibilidadRequest.establecimiento = establecimiento.value;
        disponibilidadRequest.fecha = <Date>state.fecha;
        disponibilidadRequest.servicio = <Servicio>state.servicio;

        store.dispatch(
          "disponibilidad/consultarDisponibilidadAction",
          disponibilidadRequest
        );

        router.push({ name: "disponibilidad", params: { id: id.value } });
      }
    };

    const state = reactive({
      sucursal: {},
      fecha: new Date(),
      servicio: {},
    });

    const rules = computed(() => {
      return {
        sucursal: {
          required: helpers.withMessage("Sucursal es requerida", required),
        },
        fecha: {
          required: helpers.withMessage("Fecha es requerida", required),
        },
        servicio: {
          required: helpers.withMessage("Servicio es requerido", required),
        },
      };
    });

    const seleccionarSucursal = () => {
      console.log(state.sucursal + "");
      sucursalSeleccionada = JSON.parse(
        JSON.stringify(state.sucursal, null, 2)
      );
      servicios.value = <Servicio[]>sucursalSeleccionada.servicios;
    };

    const v$ = useVuelidate(rules, state);

    onMounted(() => {
      store.dispatch(
        "establecimiento/consultarEstablecimientoAction",
        id.value
      );

      store.dispatch(
        "aforos/consultarAforosPorEstablecimientoAction",
        id.value
      );

      store.dispatch(
        "sucursales/consultarSucursalesPorEstablecimientoAction",
        id.value
      );

      store.dispatch("reserva/actualizaProgresoreservaAction", 0);
    });

    return {
      establecimiento,
      consultandoEstablecimiento,
      saveChanges,
      sucursales,
      seleccionarSucursal,
      servicios,
      state,
      v$,
    };
  },
});
