import { Servicio } from "./servicio.model";
import { Sucursal } from "./sucursal.model";


export class DisponibilidadRequest {
    fecha?: Date;
    establecimiento?: string;
    todasSucursales?: boolean;
	todosServicio?: boolean;
    todosProfesionales?: boolean;
    sucursal?: Sucursal;
    servicio?: Servicio;
    
    constructor(){
        this.todasSucursales=true;
	    this.todosServicio=true;
        this.todosProfesionales=true;
    }
}