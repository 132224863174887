<template>
  <div
    v-if="!consultandoEstablecimiento"
    class="page d-flex flex-row flex-column-fluid"
  >
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <div class="card mb-5 mb-xxl-8 custom-card h-100 px-5">
        <div class="card-body pt-9 pb-0">
          <div class="row">
            <div
              class="
                notice
                d-flex
                bg-light-secondary
                rounded
                border-gray border border-dashed
                mb-9
                p-6
              "
            >
              <span class="me-4">
                <img
                  src="media/icons/duotone/Code/Warning-1-circle.png"
                  alt="image"
                />
              </span>

              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <div class="fs-6 text-gray-600">
                    Por favor ingresa los siguientes datos.
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>

          <div class="row">
            <!--Sucursal-->
            <div class="col-lg-8 fv-row">
              <label class="col-lg-8 col-form-label fw-bold">
                <span class="required">Sucursal</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Seleccione la sucursal mas cercana"
                ></i>
              </label>
              <div
                class="form-group"
                :class="{ 'form-group--error': v$.sucursal.$error }"
              >
                <select
                  @change="seleccionarSucursal()"
                  name="sucursal"
                  class="form-select form-select-solid form-select-lg fw-bold"
                  v-model.trim="state.sucursal"
                >
                  <option
                    :value="sucursal"
                    v-for="sucursal in sucursales"
                    :key="sucursal.id"
                  >
                    {{ sucursal.nombre }}
                  </option>
                </select>
                <!-- error message -->
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <div
                      class="input-errors"
                      v-for="(error, index) of v$.sucursal.$errors"
                      :key="index"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <!-- fin error -->
              </div>
            </div>
            <!--Fin Sucursal-->

            <!--Fecha-->
            <div class="col-lg-4 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Fecha de la reserva</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Seleccione la fecha para revisar disponibilidad"
                ></i>
              </label>
              <input
                type="date"
                class="
                  form-control form-control-lg form-control-solid
                  mb-3 mb-lg-0
                "
                placeholder="Fecha de reserva"
                v-model="state.fecha"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.fecha.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!--Fin Fecha-->
          </div>

          <div class="row">
            <!--servicio-->
            <div class="col-lg-12 fv-row">
              <label class="col-lg-12 col-form-label fw-bold fs-6">
                <span class="required">Servicio</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Seleccione el servicio para realizar la reserva"
                ></i>
              </label>
              <select
                name="servicio"
                class="form-select form-select-solid form-select-lg fw-bold"
                v-model="state.servicio"
              >
                <option
                  :value="servicio"
                  v-for="servicio in servicios"
                  :key="servicio.id"
                >
                  {{ servicio.nombre }}
                </option>
              </select>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <!-- error message -->
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <div
                        class="input-errors"
                        v-for="(error, index) of v$.servicio.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- fin error -->
                </div>
              </div>
            </div>

            <!--servicio-->
          </div>

          <div class="row pt-5">
            <div
              class="
                notice
                d-flex
                bg-light-primary
                rounded
                border-grey border border-dashed
                mb-9
                p-6
              "
            >
              <span class="me-4">
                <img
                  src="media/icons/duotone/Code/Warning-1-circle.png"
                  alt="image"
                />
              </span>

              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <div class="fs-6 text-gray-600">
                    Es posible que algunos profesionales no trabajen todos los
                    días, por consiguiente si no encuentras el servicio que
                    estas buscando intenta por favor con otro día.
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>

          <div class="col-lg-6 fv-row">
            <button @click="saveChanges" class="btn btn-success btn-lg">
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import {
  onMounted,
  ref,
  toRefs,
  computed,
  defineComponent,
  reactive,
} from "vue";
import { useRouter } from "vue-router";
import { DisponibilidadRequest } from "@/models/disponibilidad-request.model";
import { Sucursal } from "@/models/sucursal.model";
import { Servicio } from "@/models/servicio.model";

export default defineComponent({
  name: "inicio",

  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { id } = toRefs(props);

    let servicios = ref({});

    const store = useStore();

    const router = useRouter();

    let sucursalSeleccionada: Sucursal;

    const consultandoEstablecimiento = computed(
      () => store.getters["establecimiento/getterConsultandoEstablecimiento"]
    );

    const sucursales = computed(
      () => store.getters["sucursales/getterSucursales"]
    );

    const establecimiento = computed(
      () => store.getters["establecimiento/getterEstablecimiento"]
    );

    const saveChanges = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
        let disponibilidadRequest: DisponibilidadRequest =
          new DisponibilidadRequest();
        disponibilidadRequest.sucursal = sucursalSeleccionada;
        disponibilidadRequest.establecimiento = establecimiento.value;
        disponibilidadRequest.fecha = <Date>state.fecha;
        disponibilidadRequest.servicio = <Servicio>state.servicio;

        store.dispatch(
          "disponibilidad/consultarDisponibilidadAction",
          disponibilidadRequest
        );

        router.push({ name: "disponibilidad", params: { id: id.value } });
      }
    };

    const state = reactive({
      sucursal: {},
      fecha: new Date(),
      servicio: {},
    });

    const rules = computed(() => {
      return {
        sucursal: {
          required: helpers.withMessage("Sucursal es requerida", required),
        },
        fecha: {
          required: helpers.withMessage("Fecha es requerida", required),
        },
        servicio: {
          required: helpers.withMessage("Servicio es requerido", required),
        },
      };
    });

    const seleccionarSucursal = () => {
      console.log(state.sucursal + "");
      sucursalSeleccionada = JSON.parse(
        JSON.stringify(state.sucursal, null, 2)
      );
      servicios.value = <Servicio[]>sucursalSeleccionada.servicios;
    };

    const v$ = useVuelidate(rules, state);

    onMounted(() => {
      store.dispatch(
        "establecimiento/consultarEstablecimientoAction",
        id.value
      );

      store.dispatch(
        "aforos/consultarAforosPorEstablecimientoAction",
        id.value
      );

      store.dispatch(
        "sucursales/consultarSucursalesPorEstablecimientoAction",
        id.value
      );

      store.dispatch("reserva/actualizaProgresoreservaAction", 0);
    });

    return {
      establecimiento,
      consultandoEstablecimiento,
      saveChanges,
      sucursales,
      seleccionarSucursal,
      servicios,
      state,
      v$,
    };
  },
});
</script>

<style scoped>
.custom-card {
  margin: 0px 17px 17px 17px;
  background-color: #fff;
}

.form-group--error {
  display: block;
  color: #f57f6c;
}

.form__input {
  border-color: #f79483;
}
</style>